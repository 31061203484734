/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import smoothscroll from 'smoothscroll-polyfill';
import './src/assets/fonts/stylesheet.css';

smoothscroll.polyfill();

const swapElements = () => {
  const asteriskEl = document.querySelector('.ao-required');
  const formTextBlockEl = document.querySelector('.ao-richtext-block');
  const asteriskTextEl = document.createElement('span');
  asteriskTextEl.innerText = 'Verplichte velden';
  asteriskTextEl.style = 'font-size: 10pt; color: #323232;';

  if (!asteriskEl.querySelector('*')) {
    asteriskEl.appendChild(asteriskTextEl);
  }

  formTextBlockEl.insertBefore(
    asteriskEl.parentElement,
    formTextBlockEl.firstChild
  );
};

const loadForm = () => {
  if (typeof window._aoFormLoader === 'undefined') {
    window.setTimeout(
      loadForm,
      200
    ); /* this checks the flag every 100 milliseconds*/

    const checkForm = window.setInterval(() => {
      const formEl = document.querySelector('.ao-form');

      if (formEl) {
        swapElements();
        clearInterval(checkForm);
      }
    }, 200);
  } else {
    window._aoFormLoader.load({
      id: 'e9f55349-034e-4627-b92a-ee934fd531aa:d-0001',
      accountId: '41947',
      domain: 'nu.esri.nl',
      isTemp: false,
      noStyle: true,
      prefill: true,
    });
  }
};

export const onRouteUpdate = ({ location }) => {
  if (location.pathname.indexOf('/klimaat') !== -1) {
    if (typeof window._aoFormLoader === 'undefined') {
      !(function(o, t, e, a) {
        (o._aoForms = o._aoForms || []), o._aoForms.push(a);
        const n = function() {
          const o = t.createElement(e);
          (o.src =
            ('https:' == t.location.protocol ? 'https://' : 'http://') +
            'nu.esri.nl/acton/content/form_embed.js'),
            (o.async = !0);
          t.head.appendChild(o);
        };

        window.attachEvent
          ? window.attachEvent('onload', n)
          : window.addEventListener('load', n, !1),
          n();
      })(window, document, 'script', {
        id: 'e9f55349-034e-4627-b92a-ee934fd531aa',
        accountId: '41947',
        domain: 'nu.esri.nl',
        isTemp: false,
        noStyle: true,
        prefill: true,
      });
    }

    loadForm();
  }
};
